<template>
	<div class="container">
		<div class="title">
      <span class="inner_title">新增员工</span>
    </div>
		<div>
      <div class="item-box">
				<span class="item-left">用户名：</span>
				<el-input class="rantHouseName" v-model="user_name"  placeholder="请输入"></el-input>
			</div>
      <div class="item-box">
				<span class="item-left">性别：</span>
				<el-radio-group v-model="sex" @change='sexChange'>
          <el-radio :label="0">男</el-radio>
          <el-radio :label="1">女</el-radio>
        </el-radio-group>
			</div>
      <div class="item-box">
				<span class="item-left">手机号：</span>
				<el-input class="rantHouseName" v-model="mobile"  placeholder="请输入"></el-input>
			</div>
      <div class="item-box">
				<span class="item-left">岗位名：</span>
				<el-select v-model="position_id" placeholder="请选择">
          <el-option
            v-for="item in postList"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
			</div>
      <div class="item-box">
				<span class="item-left">时间</span>
				<el-date-picker
          v-model="entry_date"
          type="date"
          placeholder="选择日期"
          value-format ="yyyy-MM-dd">
        </el-date-picker>
			</div>
			<div class="sub-box">
        <!-- 新增 -->
				<el-button v-if="!id" class="submitBtn" type="primary" plain @click="submitAdd">提交</el-button>
        <!-- 编辑 -->
        <el-button v-else class="submitBtn" type="primary" plain @click="submitEdit">提交</el-button>
			</div>
		</div>
	</div>
</template>
<script>
  import {addStaffFn,getPostListFn,editDepartmentFn } from '@/api/department.js'
	export default {
		props: [],
		data() {
			return {
        id:'',
        
        user_name:'',
        sex:0,
        mobile:'',
        postList:[],
        position_id:'',
        entry_date:''
      }
		},
    created() {
      this.getPostList()
      this.id = this.$route.query.id
      if (this.id){
        this.getDepartmentDetails()
      }
    },
		mounted() {
		},
		methods: {
      //性别改变
      sexChange(value){
        this.sex = value
      },
      //获区岗位列表
			getPostList() {
				this.loading = true;
        let params = {
          api_token: localStorage.getItem('token1'),
					page: 1,
					page_size: 9999,
        }
				getPostListFn(params).then(res => {
					this.postList = res.datas.data;
					this.loading = false;
				})
			},
      //获取部门详情
      getDepartmentDetails(){
        let params = {
          api_token:localStorage.getItem('token1'),
          id:this.id
        }
        getDepartmentDetailsFn(params).then(res => {
          this.title = res.datas.title
        })
      },
      submitEdit(){
        let params ={
          api_token:localStorage.getItem('token1'),
					title:this.title,
          id:this.id
        }
				editDepartmentFn(params).then(res => {
					if (res.code == 200) {
						this.$confirm("提交成功", "提交成功", {
							confirmButtonText: "确定",
							callback: () => {
								this.$router.push({
									path: "/postList",
									query: {
										page: this.page,
										last_page: this.last_page
									}
								});
							}
						});
					}
				});
      },
			//提交
			submitAdd() {
        let params ={
          api_token:localStorage.getItem('token1'),
          department_id:this.department_id,
					user_name:this.user_name,
          sex:this.sex == 0 ? 'MALE' : 'FEMALE',
          mobile:this.mobile,
          position_id:this.position_id,
          entry_date:this.entry_date,
        }
				addStaffFn(params).then(res => {
					if (res.code == 200) {
						this.$confirm("提交成功", "提交成功", {
							confirmButtonText: "确定",
							callback: () => {
								this.$router.push({
									path: "/staffList",
									query: {
										page: this.page,
										last_page: this.last_page
									}
								});
							}
						});
					}
				});
			},
		},
	};
</script>
<style scoped>
	@import url("../../../style/detail.css");
</style>
